<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-alert
                        border="left"
                        color="info"
                        text
                >
                    <div v-html="trans('pages.domain.vendorSubDomainDescription')"></div>
                </v-alert>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="item.name"
                            :label="trans('fields.common.subdomain')"
                            :error-messages="full_name_errors"
                            @input="clearError('name')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            v-model="item.vendor_domain_id"
                            :label="trans('fields.common.domainName')"
                            :items="options.vendorDomains"
                            :multiple="false"
                            :error-messages="errors.vendor_domain_id"
                            @input="clearError('vendorDomain')"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "DomainForm",
        mixins: [formValidate],
        props: {
            itemId: {
                type: [Number, undefined],
            },
            user_id: {
                type: [Number, undefined],
            },
            landing_id: {
                type: [Number, undefined],
            },
        },
        data(){
            return {
                item: {
                    id: this.itemId,
                    name: '',
                    user_id: this.user_id,
                    vendor_domain_id: this.landing_id,
                },
                options: {},
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                if(this.itemId){
                    axios.get(this.route('user.domains.show', this.itemId)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    })
                } else {
                    this.loadOptions();
                }
            },
            loadOptions(){
                axios.post(this.route('user.options'), {
                        vendorDomains: true,
                    }).then( response => {
                    this.options = response.data;
                })
            },
            save(){
                if (this.item.id){
                    axios.post(this.route('user.domains.update', this.item.id), this.item).then( (response) => {
                        this.$emit('updated', response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( err => {
                        this.errors = err.response.data.errors;
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route('user.domains.vendor.store'), this.item).then( (response) => {
                        this.$emit('saved', response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( err => {
                        this.errors = err.response.data.errors;
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            }
        },
        computed: {
            full_name_errors(){
                let err = [];
                if(this.errors){
                    if (this.errors.full_name){
                        err = [...err, ...this.errors.full_name];
                    }
                    if (this.errors.name){
                        err = [...err, ...this.errors.name];
                    }
                }
                return err;
            },
            formTitle() {
                return this.trans('pages.domain.newDialogVendorTitle');
            },
        }
    }
</script>
